﻿/**
 *  @ngdoc controller
 *  @name Drilldot Directive
 *  @description Drill-Dot Directive
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('notchModel', function ($timeout) {
        return {
            controller: 'notchController',
            restrict: 'E',
            scope: {
                modelId: "@",
                data: '=',
                eyeData: '='
            },
            templateUrl: 'app/directive/notch/notchdir.html'
        }
    }).controller('notchController', function ($scope, eyeResource) {
        $scope.urlInNewTab = function (i) {
            if (i.enableDelete == false) {
                window.open(i.url);
            } else {
                if (i.imageExtn.toLowerCase() != "pdf") {
                    var image = new Image();
                    image.src = i.url;
                    var w = window.open("");
                    w.document.write(image.outerHTML);
                } else {
                    var win = window.open();
                    win.document.write('<iframe src="' + i.url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
                }
            }
        }
    });

}(window.angular));